import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './Footer.css';

//IMAGES
import twitLogo from '../assets/images/twit-white.png';


const Footer = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
    
      window.addEventListener('resize', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    


    const scrollToTopAndNavigate = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
        // Add your navigation logic here
    };
    

    return (
        <footer className="footer-container">
            <div className="footer-branding">
            <Link to="/" className="footer-brand" onClick={scrollToTopAndNavigate}>
                <span className="bumpups-brand">bump</span>
                <span className="bumpups-brand-color">ups</span>
            </Link>
                <div className="brand-description">
                    <span className="timestamp-highlight">#1 AI VIDEO ASSISTANT</span> Bumpups will chat with any video and deliver insights across all industries in just one click.
                    {/* <div className="ai-nexus-badge">
                    <a href="https://www.webcafeai.com" target="_blank" rel="noopener noreferrer">
    <img src={cafeLogo} alt="Webcafe Logo" className="ai-nexus-logo" />
                    </a>                        Webcafe AI
                    </div> */}
                    <div className="social-media-icons">
                    <a href="https://twitter.com/bumpups" target="_blank" rel="noopener noreferrer">
                        <img src={twitLogo} alt="Twitter" />
                    </a>
                </div>



                </div>
            </div>
                {/* Desktop-specific footer sections */}
                {!isMobile && (
                    <>
                    <div className="footer-section">
                        <h4>Company</h4>
                        <ul className="footer-links">
                        <li>
                        <Link to="/pricing" className="contact-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTopAndNavigate}>
                            Pricing
                        </Link>
                    </li>
                    {/* <li>
                    <Link to="/aboutus" className="contact-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTopAndNavigate}>
                        About us
                    </Link>
                    </li> */}
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h4>Support</h4>
                        <ul className="footer-links">
                        <li><a href="https://intercom.help/bumpups/en/" target="_blank" rel="noopener noreferrer" className="contact-link">Help Center</a></li>
                        </ul>
                    </div>
                    
                    </>
                )}

              {/* Mobile-specific footer section */}
            {isMobile && (
                <div className="footer-section mobile-footer">
                <h4>Company</h4>
                <ul className="footer-links">
                <li>
                <Link to="/pricing" className="contact-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTopAndNavigate}>
                            Pricing
                        </Link>
                </li>
                {/* <li>
                <Link to="/aboutus" className="contact-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={scrollToTopAndNavigate}>
                        About us
                    </Link>
                </li> */}
                <li><a href="https://intercom.help/bumpups/en/" target="_blank" rel="noopener noreferrer" className="contact-link">Help Center</a></li>
                </ul>
                </div>

            )}

            <div className="footer-bottom" style={isMobile ? { textAlign: 'center', paddingLeft: '0px' } : {}}>
                ©2024 Bumpups - All rights reserved. 
                <Link to="/terms-of-service" className="contact-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => scrollToTopAndNavigate('/terms-of-service')}>
                Terms of Service
            </Link>               
                <Link to="/privacy-policy" className="contact-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => scrollToTopAndNavigate('/privacy-policy')}>
                Privacy Policy
            </Link>            
    
    </div>
        </footer>
    );
}

export default Footer;