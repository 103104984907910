import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

// FIREBASE
import { db, auth } from '../firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

// FUNCTIONS
import { AuthContext } from '../AuthContext';

// IMPORT UI
import CloseSidebar from './CloseSidebar';

// IMAGES
import bumpUPlogo from '../assets/images/comment-v2.png';
import helpIcon from '../assets/images/v2-helpicon.png';
import dashboardLogo from '../assets/images/dashboard-logo-v6.png';
import creatoricon from '../assets/images/v2-creator.png';
import subicon from '../assets/images/v2-plan.png';
import linkIcon from '../assets/images/link-v3.png';
import uploadLogo from '../assets/images/upload-white-v1.png';
import analyticsIcon from '../assets/images/anayltics-icon.png';

function Sidebar() {
  const location = useLocation();
  const currentView = location.pathname;
  const { currentUser } = useContext(AuthContext);

  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 1280);

  // Retrieve the dropdown state from localStorage or default to closed (false)
  const initialDropdownState = currentUser
    ? localStorage.getItem(`dropdownOpen_${currentUser.uid}`) === 'true'
    : false;
  const [isDropdownOpen, setIsDropdownOpen] = useState(initialDropdownState);

  const initialState = localStorage.getItem(`currentPlan_${auth.currentUser?.uid}`) || 'Free Plan';
  const [currentPlan, setCurrentPlan] = useState(initialState);

  const initialLanguage = localStorage.getItem(`userLanguage_${auth.currentUser?.uid}`) || '🇺🇸 English';
  const [language, setLanguage] = useState(initialLanguage);

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 1280);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const languages = [
      { value: 'en', label: 'en', name: '🇺🇸 English' },
      { value: 'hi', label: 'hi', name: '🇮🇳 Hindi' },
      { value: 'es', label: 'es', name: '🇪🇸 Spanish' },
      { value: 'pt', label: 'pt', name: '🇵🇹 Portuguese' },
      { value: 'ru', label: 'ru', name: '🇷🇺 Russian' },
      { value: 'de', label: 'de', name: '🇩🇪 German' },
      { value: 'fr', label: 'fr', name: '🇫🇷 French' },
      { value: 'ja', label: 'ja', name: '🇯🇵 Japanese' },
      { value: 'ko', label: 'ko', name: '🇰🇷 Korean' },
      { value: 'ar', label: 'ar', name: '🇸🇦 Arabic' }
    ];

    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
      const planDetailsRef = doc(db, 'users', user.uid, 'subscriptionData', 'planDetails');

      const fetchUserDetails = async () => {
        try {
          const planDoc = await getDoc(planDetailsRef);
          if (planDoc.exists()) {
            const currentPlan = planDoc.data().currentPlan;
            setCurrentPlan(currentPlan);
            localStorage.setItem(`currentPlan_${user.uid}`, currentPlan);
          }

          const localLanguage = localStorage.getItem(`userLanguage_${user.uid}`);
          if (localLanguage) {
            setLanguage(localLanguage);
          } else {
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              const userData = userDoc.data();
              const userLanguage = userData.userDefaultLanguage;
              const selectedLanguage = languages.find(lang => lang.value === userLanguage);

              if (selectedLanguage) {
                setLanguage(selectedLanguage.name);
                localStorage.setItem(`userLanguage_${user.uid}`, selectedLanguage.name);
              } else {
                setLanguage('🇺🇸 English');
                localStorage.setItem(`userLanguage_${user.uid}`, '🇺🇸 English');
              }
            } else {
              setLanguage('🇺🇸 English');
              localStorage.setItem(`userLanguage_${user.uid}`, '🇺🇸 English');
            }
          }
        } catch (error) {
          // console.error("Error fetching user details: ", error);
          setLanguage('🇺🇸 English');
        }
      };

      fetchUserDetails();

      const unsubscribePlan = onSnapshot(planDetailsRef, (docSnap) => {
        if (docSnap.exists()) {
          const newCurrentPlan = docSnap.data().currentPlan;
          const storedCurrentPlan = localStorage.getItem(`currentPlan_${user.uid}`);

          if (!storedCurrentPlan || storedCurrentPlan !== newCurrentPlan) {
            setCurrentPlan(newCurrentPlan);
            localStorage.setItem(`currentPlan_${user.uid}`, newCurrentPlan);
          }
        }
      });

      const unsubscribeLanguage = onSnapshot(userDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const newLanguage = docSnap.data().userDefaultLanguage;
          const storedLanguage = localStorage.getItem(`userLanguage_${user.uid}`);
          const selectedLanguage = languages.find(lang => lang.value === newLanguage);

          if (selectedLanguage) {
            if (!storedLanguage || storedLanguage !== selectedLanguage.name) {
              setLanguage(selectedLanguage.name);
              localStorage.setItem(`userLanguage_${user.uid}`, selectedLanguage.name);
            }
          } else {
            if (!storedLanguage) {
              setLanguage('🇺🇸 English');
              localStorage.setItem(`userLanguage_${user.uid}`, '🇺🇸 English');
            }
          }
        }
      });

      return () => {
        unsubscribePlan();
        unsubscribeLanguage();
      };
    }
  }, [currentUser]);

  // Function to toggle dropdown and save state to localStorage
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => {
      const newState = !prevState;
      if (currentUser) {
        localStorage.setItem(`dropdownOpen_${currentUser.uid}`, newState);
      }
      return newState;
    });
  };

  const isCurrentView = (view) => currentView === view;
  const getLinkClass = (view) => `sidebar-link ${isCurrentView(view) ? 'active' : ''}`;
  const getIconClass = (view) => `link-icon ${isCurrentView(view) ? 'active-icon' : ''}`;

  if (isNarrowScreen) {
    return <CloseSidebar />;
  }

  return (
    <div className="sidebar">
      <Link 
        to="/workspace" 
        className={`bumpupload-button ${isCurrentView('/workspace') ? 'active' : ''}`} 
      >
        <div className="bumpupload-content">
          <img src={bumpUPlogo} alt="Workspace Logo" className="link-icon-bump" />
          <div className="bumpbutton-text">Start Chatting</div>
        </div>
      </Link>

      <div className="dividersides"></div>

      <Link to="/dashboard" className={getLinkClass('/dashboard')} style={{ textDecoration: 'none', color: 'inherit' }}>
        <img src={dashboardLogo} alt="Dashboard" className={getIconClass('/dashboard')} />
        Dashboard
      </Link>

      <div className="dividersides"></div>

      {/* Dropdown for Bump Social */}
      <div className="side-dropdown">
        <div
          className={`side-dropdown-toggle ${isCurrentView(currentView) ? 'active' : ''}`}
          onClick={toggleDropdown}
          role="button"
          aria-haspopup="true"
          aria-expanded={isDropdownOpen}
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggleDropdown();
            }
          }}
        >
          <img src={creatoricon} alt="Bump Social" className="link-icon" />
          YouTube Studio
          <span className={`dropdown-arrow ${isDropdownOpen ? 'open' : ''}`}></span>
        </div>

        <div className={`side-dropdown-content ${isDropdownOpen ? 'open' : ''}`}>
          {/* <Link to="/creator" className={getLinkClass('/creator')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={analyticsIcon} alt="YouTube" className={getIconClass('/creator')} />
            Channel
          </Link> */}
          <Link to="/links" className={getLinkClass('/links')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={linkIcon} alt="Links" className={getIconClass('/links')} />
            Links
          </Link>
          <Link to="/bumper" className={getLinkClass('/bumper')} style={{ textDecoration: 'none', color: 'inherit' }}>
            <img src={uploadLogo} alt="Local Videos" className={getIconClass('/bumper')} />
            Upload
          </Link>
        </div>
      </div>

      <a href="https://intercom.help/bumpups/en/" target="_blank" rel="noopener noreferrer" className={getLinkClass('/help')}>
        <img src={helpIcon} alt="Help" className={getIconClass('/help')} />
        Help
      </a>

      <div className="plan-section">
        <div className="sidebar-footer-details">
          <div className="section-title">
            <img src={subicon} alt={currentPlan} className="section-icon" />
            {currentPlan === "bump" ? "Bump Plan" : currentPlan}
          </div>
          <div className="languagedefault-title" onClick={() => window.location.href = '/settings'}>
            <span>{language}</span>
          </div>
        </div>
        <div className="plan-status">
          <Link to="/plans" className="manage-plan">Manage plan</Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
