// src/components/OneTimeSection.js

import React, { useState } from 'react';
import './OneTimeSection.css';
import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';
import timeLogo from '../assets/images/clock-v2.png';

// FIREBASE
import { getFunctions, httpsCallable } from "firebase/functions";

// STRIPE
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe outside the component to ensure it's loaded only once
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const OneTimeSection = () => {
  const [selectedPlan, setSelectedPlan] = useState('base');
  const [selectedAmount, setSelectedAmount] = useState(100);
  const [stripeButtonText, setStripeButtonText] = useState("Proceed to Stripe");

  // Define pricing rates for each plan
  const rates = {
    base: 0.025,  // $0.025 per upload minute for Base Plan
    bump: 0.10,   // $0.10 per upload minute for Bump Plan
  };

  // Calculate the price based on selected plan and amount
  const calculatePrice = () => {
    const rate = rates[selectedPlan];
    const price = selectedAmount * rate;
    return price.toFixed(2); // Format to two decimal places
  };

  const price = calculatePrice(); // e.g., "10.00"

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  const handleAmountChange = (amount) => {
    setSelectedAmount(amount);
  };

  const startCheckoutSession = async () => {
    setStripeButtonText("Loading...");
    try {
      const functions = getFunctions();
      const processCheckout = httpsCallable(functions, 'processCheckout');
  
      // Retrieve gclid from localStorage
      const gclid = localStorage.getItem('gclid') || '';
  
      // Prepare payload
      const payload = {
        selectedMinutes: selectedAmount, 
        planType: selectedPlan.charAt(0).toUpperCase() + selectedPlan.slice(1), // Capitalize first letter
        gclid: gclid,
        purchaseType: 'one_time', // Set purchase type to 'one_time'
      };
      // console.log('Checkout Payload:', payload);

      // Call the Firebase function with the prepared payload
      const response = await processCheckout(payload);
      
      const { id } = response.data;
      const stripe = await stripePromise;
  
      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
  
      if (error) {
        // Handle Stripe redirection errors
        setStripeButtonText("Retry");
      } else {
        // Reset button text if redirection is successful
        setStripeButtonText("Proceed to Stripe");
      }
    } catch (error) {
      // Handle errors from the backend function
      setStripeButtonText("Retry");
    }
  };

  const handleProceed = () => {
    startCheckoutSession();
  };

  // Determine the appropriate class based on the selected plan
  const planClass = selectedPlan === 'base' ? 'base-plan' : 'bump-plan';

  // Function to handle "LEARN MORE" button click
  const openLink = () => {
    window.open('https://intercom.help/bumpups/en/articles/8858577-how-are-credits-deducted', '_blank'); // Replace with your desired URL
  };

  // Content for the processing container
  const processingContent = {
    title: "Your processing time",
    description: "Processing time on Bumpups is deducted per video, with beneficial rounding up.",
  };

  // Define amount options
  const amountOptions = [100, 200, 400, 600, 800, 1000];

  return (
    <div className={`onetime-plan-box ${planClass}`}>
      {/* Title Section with "New" Badge */}
      <div className="onetime-title-container">
        <div className="onetime-title">One Time Purchase</div>
        <span className="onetime-badge">🎉 New</span>
      </div>

      {/* Plan Selection */}
      <div className="onetime-plan-selection">
        <div className="onetime-plan-options">
          <div
            className={`onetime-plan-option base-plan-option ${selectedPlan === 'base' ? 'selected' : ''}`}
            onClick={() => handlePlanChange('base')}
          >
            <img src={basePlanLogo} alt="Base Plan Logo" className="onetime-plan-logo" />
            <div className="onetime-plan-info">
              <h3>Base Credits</h3>
              <p>For YouTube Links</p>
            </div>
          </div>
          <div
            className={`onetime-plan-option bump-plan-option ${selectedPlan === 'bump' ? 'selected' : ''}`}
            onClick={() => handlePlanChange('bump')}
          >
            <img src={bumpPlanLogo} alt="Bump Plan Logo" className="onetime-plan-logo" />
            <div className="onetime-plan-info">
              <h3>Bump Credits</h3>
              <p>For Local Upload</p>
            </div>
          </div>
        </div>
      </div>

      {/* Price Display */}
      <div className="onetime-price">
        ${price} USD
      </div>

      {/* Selected Amount Display */}
      <div className="onetime-selected-amount">
        {selectedAmount} Upload {selectedAmount === 1 ? 'Minute' : 'Minutes'}
      </div>

      {/* Amount Selection as Buttons */}
      <div className="onetime-amount-selection">
        <span className="onetime-amount-label">Receive credits instantly</span>
        <div className="onetime-amount-buttons">
          {amountOptions.map((amount) => (
            <button
              key={amount}
              className={`onetime-amount-btn ${selectedAmount === amount ? 'selected' : ''}`}
              onClick={() => handleAmountChange(amount)}
              aria-pressed={selectedAmount === amount}
            >
              {amount}
            </button>
          ))}
        </div>
      </div>

      {/* Proceed Button */}
      <button className="onetime-proceed-btn" onClick={handleProceed}>
        {stripeButtonText}
      </button>

      {/* New Processing Container Stacked Below */}
      <div className="onetime-processing-container">
        <div className="onetime-processing-card">
          <div className="onetime-processing-content">
            <div className="onetime-subtitle">How it works?</div>
            <div className="onetime-title-article">{processingContent.title}</div>
            <div className="onetime-description">{processingContent.description}</div>
            <div className="onetime-processing-actions">
              <button className="onetime-processing-start-btn" onClick={openLink}>LEARN MORE</button>
            </div>
          </div>
          <div className="onetime-processing-icon">
            <img className="onetime-upload-icon" src={timeLogo} alt={processingContent.title} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneTimeSection;
